import React, { Component, useState, useEffect } from 'react'

import CompletedApplication from './CompletedApplication'
import TotalApplication from './TotalApplication'
import CB from './CB'
import HB from './HB'
import Header from '../../Header';
import Menu from '../../Menu';
import Payment from './Payment'
function Dashboard() {
  // const [filter, setFilter] = useState('Partial Application Filled Data')
  const [filter, setFilter] = useState('')
  const [enggStudentsPartial, setMbaStudents] = useState({})
  const [enggStudentsCompletedAppl, setMbaStudentsCompleted] = useState({})
  const [cbe, setCbe] = useState({})
  const [hbe, setHbe] = useState({})
  // console.log(mbaStudentsPartial)
  const mbaStudents = async () => {
    fetch('/engg-students-partial')
      .then(response => response.json())
      .then(data => {
        setMbaStudents(data)


      })
      .catch(error => {
        console.log(error)
      })
  }
  const mbaStudentsCompleted = async () => {
    fetch('/engg-students-completed')
      .then(response => response.json())
      .then(data => {
        setMbaStudentsCompleted(data)


      })
      .catch(error => {
        console.log(error)
      })
  }
  const mbaStudentsHBE = async () => {
    fetch('/engg-students-home-based')
      .then(response => response.json())
      .then(data => {
        setHbe(data)


      })
      .catch(error => {
        console.log(error)
      })
  }
  const mbaStudentsCBE = async () => {
    fetch('/engg-students-center-based')
      .then(response => response.json())
      .then(data => {
        setCbe(data)


      })
      .catch(error => {
        console.log(error)
      })
  }

  const [data, setData] = useState()
  const [total, setTotal] = useState()
  const pcb = async () => {
    fetch('/pcb')
      .then(response => response.json())
      .then(data => {
        setData(data.application)
        setTotal(data.total)
        console.log(data)
      })
      .catch(error => {
        console.log(error)
      })
  }
  useEffect(() => {
    // mbaStudents()
    // mbaStudentsCompleted()
    // mbaStudentsCBE()
    // mbaStudentsHBE()
    pcb()
  }, [])

  const partialData = data?.filter(item => item.payStatus === false);
  const homeBasedData = data?.filter(item => item.examinationMode === 'Home Based Examination' && item.payStatus == true);
  const completedData = data?.filter(item => item.payStatus == true);
  const totalHB = data?.filter(item => item.examinationMode === 'Home Based Examination' && item.payStatus == true).length;


  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">PCB Application - 2023</h1>
              </div>{/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">Dashboard v1</li>
                </ol>
              </div>{/* /.col */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            {/* Small boxes (Stat box) */}
            <div className="row">
              <div className="col-lg-3 col-6" onClick={() => { setFilter('Partial Application Filled Data') }}>
                {/* small box */}
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>{total && total.unpaidStudent}</h3>
                    <p>Partial Completed</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-bag" />
                  </div>
                  <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6" onClick={() => { setFilter('Total Successfully Application Completed Data') }}>
                {/* small box */}
                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>{total && total.paidStudent}</h3>
                    <p>Application Completed</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-stats-bars" />
                  </div>
                  <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6" onClick={() => { setFilter('Center Based Application Data') }}>
                {/* small box */}
                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>0</h3>
                    <p>Center Based Application</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6" onClick={() => { setFilter('Home Based Application Data') }}>
                {/* small box */}
                <div className="small-box bg-secondary">
                  <div className="inner">
                    <h3>{totalHB || 0}</h3>
                    <p>Home Based Application</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-pie-graph" />
                  </div>
                  <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                </div>
              </div>
              {/* ./col */}
              {/* ./col */}
              <div className="col-lg-3 col-6" onClick={() => { setFilter('Payment Of Students') }}>
                {/* small box */}
                <div className="small-box " style={{ backgroundColor: '#8790cb' }}>
                  <div className="inner">
                    <h3>{total && total.paidStudent}</h3>
                    <p>Total Payment</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-pie-graph" />
                  </div>
                  <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                </div>
              </div>
              {/* ./col */}
            </div>
            <center><h2>{filter}</h2></center>
            {
              filter === "Partial Application Filled Data" ? <><CompletedApplication data={partialData && partialData} />
              </> :
                <></>
            }
            {
              filter === "Total Successfully Application Completed Data" ? <><TotalApplication data={completedData && completedData} />
              </> :
                <></>
            }
            {
              filter === "Center Based Application Data" ? <><CB data={data && data} />
              </> :
                <></>
            }
            {
              filter === "Home Based Application Data" ? <><HB data={homeBasedData && homeBasedData} />
              </> :
                <></>
            }
            {
              filter === "Payment Of Students" ? <><Payment data={completedData && completedData} />
              </> :
                <></>
            }


          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  )
}

export default Dashboard
