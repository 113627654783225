import React, { useState, useEffect } from 'react';
// import Sidebar from './Sidebar'
//Bootstrap and jQuery libraries
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
// //Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
//For API Requests
import axios from 'axios';

const Payment = (props) => {
    const [data, setState] = useState([])

    // State array variable to save and show data
    const fetchData = () => {
        axios.get('/engg-students-partial').then(res => {
            //Storing users detail in state array object
            setState({ data: res.data });

            // console.log("dataAPI", res.data.userList);

        });
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable(
                    {
                        pagingType: 'full_numbers',
                        pageLength: 15,
                        processing: true,
                        dom: 'Bfrtip',
                        buttons: ['copy', 'csv', 'print', 'excelHtml5', 'pdf'
                        ]
                    }
                );
            },
                1000
            );
        });
    }
    useEffect(() => {
        fetchData()
    }, [])
    // .


    //Datatable HTML
    return (
        <div style={{ width: '100%' }}>

            <div className="flex flex-col md:flex-row" style={{ width: '100%' }}>
                {/* <Sidebar /> */}
                <section style={{ width: '100%' }}>
                    <div
                        id="main"
                        className="main-content flex-1 bg-gray-100 mt-12 md:mt-2 pb-24 md:pb-5"
                        style={{ width: '100%' }}
                    >

                        <div className="flex flex-wrap bg-white" style={{ width: '100%' }}>


                            <div className="flex flex-col bg-white" style={{ width: '100%' }}>
                                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="overflow-x-auto">
                                            <div className="MainDiv">




                                                <div className="container p-5">

                                                <table id="example" class="table table-hover table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Sl.No</th>
                                                                <th>Application No</th>
                                                                <th>User ID</th>
                                                                <th>Student Name</th>
                                                                <th>Father's Name</th>
                                                                <th>Country</th>
                                                                <th>State</th>
                                                                <th>Gender</th>
                                                                <th>Email-ID</th>
                                                                <th>Mobile No</th>
                                                                <th>Father's No</th>
                                                                <th>City</th>

                                                                <th>Program</th>
                                                                <th>Exam Slot</th>
                                                                <th>Registration Date</th>
                                                                <th>Password</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {props.data?.map((result, slNo) => {
                                                                const date = new Date(result.user?.createdAt || '2024-10-23T05:04:37.737Z') 

                                                                // Format the date (e.g., DD/MM/YYYY)
                                                                const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')
                                                                    }/${date.getFullYear()}`;
                                                                console.log(formattedDate)
                                                                return (

                                                                    <>

                                                                        <tr>
                                                                            <td>{slNo + 1}</td>
                                                                            <td>{result.applicationNo}</td>
                                                                            <td>{result.user?._id}</td>
                                                                            <td>{result.user?.name}</td>
                                                                            <td>{result.personaldetails[0] && result.personaldetails[0].fatherName}</td>
                                                                            <td>{result.user?.country}</td>
                                                                            <td>{result.personaldetails[0] && result.personaldetails[0].state}</td>
                                                                            <td>{result.user?.gender}</td>
                                                                            <td>{result.user?.email}</td>
                                                                            <td>{result.user?.mobileNo}</td>
                                                                            <td>{result.personaldetails[0] && result.personaldetails[0].guardianNo}</td>
                                                                            <td>{result.personaldetails[0] && result.personaldetails[0].city}</td>
                                                                            <td>{result.branchPref}</td>
                                                                            <td>{result.examSlot}</td>
                                                                            <td>{formattedDate}</td>
                                                                            <td>{result.user?.password}</td>
                                                                            {/* <td>{slNo + 1}</td>
                                                                            <td>{result}</td>
                                                                            <td>{result}</td>
                                                                            <td>{result}</td>
                                                                            <td>{result}</td>
                                                                            <td>{result}</td>
                                                                            <td><button className='primary'>View</button></td> */}

                                                                        </tr>

                                                                    </>

                                                                )
                                                            })}


                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </div>
        </div>
    );

}
export default Payment;