import React, { useEffect,useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppContext } from './context/appContext'
function Menu() {
  const { user } = useAppContext()
  const users = localStorage.getItem('user')
  
 
  console.log(users)
  const navigate = useNavigate()
  useEffect(() => {
    if (!users) {
      setTimeout(() => {
        navigate('/referal/login')
      }, 1)
    }

  }, [user, navigate])
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <a href="#" className="brand-link">
          <img src="https://aicee.in/static/media/logo1.32bf711ad21b08f4f19d.png" alt="" className="brand-image  elevation-3" style={{ opacity: '.8' }} />
          <span className="brand-text font-weight-light">AICEE - 2023</span>
        </a>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}

          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              {/* Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library */}
              <li className="nav-item has-treeview menu-open">
                <Link to="/" className="nav-link active">
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>
                    Dashboard

                  </p>
                </Link>
                <ul className="nav nav-treeview">



                </ul>
              </li>
              <li className="nav-item">
                {
                  user ? <>
                    {user.role === "Admin" && (
                      <>
                        <Link to="/change-markey" className="nav-link">
                          <i className="nav-icon fas fa-th" />
                          <p>
                            Change Markey
                            <span className="right badge badge-danger">New</span>
                          </p>
                        </Link>
                      </>
                    )}
                  </> : null
                }
              </li>

              {
                user ? <>
                  {
                    user.role === 'Admin' && (
                      <>
                        <li className="nav-item has-treeview">
                          <Link className="nav-link" to=''>
                            <i className="nav-icon fas fa-chart-pie" />
                            <p>
                              Application
                              <i className="right fas fa-angle-left" />
                            </p>
                          </Link>
                          <ul className="nav nav-treeview">
                            <li className="nav-item">
                              <Link to="/mba-dashboard" className="nav-link">
                                <i className="far fa-circle nav-icon" />
                                <p>MBA / PGDM</p>
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to="/Engg-dashboard" className="nav-link">
                                <i className="far fa-circle nav-icon" />
                                <p>Engineering</p>
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to="/PCM-dashboard" className="nav-link">
                                <i className="far fa-circle nav-icon" />
                                <p>PCM</p>
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to="/PCB-dashboard" className="nav-link">
                                <i className="far fa-circle nav-icon" />
                                <p>PCB</p>
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to="other-dashboard" className="nav-link">
                                <i className="far fa-circle nav-icon" />
                                <p>Other</p>
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </>
                    )
                  }
                </> : null
              }
              {
                user && user.role == "Admin" && <>
                <li className="nav-item">
                <Link to="/dashboard/bdm/user" className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    ADD BDM
                    <span className="right badge badge-danger">New</span>
                  </p>
                </Link>
              </li>
                </>
              }
              {
                user ? <>
                  {user.role === "bdm" && (<>
                    <li className="nav-item">
                      <Link to="/referal/add-user" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Refferal Add User
                          <span className="right badge badge-danger">New</span>
                        </p>
                      </Link>
                    </li>
                  </>)}
                </> : null
              }
              {
                user ? <>
                  {user.role == "BDM" || user.role == "Admin" && (<>
                    <li className="nav-item">
                      <Link to="/referal/add-user" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Add Refferal User
                          <span className="right badge badge-danger">New</span>
                        </p>
                      </Link>
                    </li>
                  </>)}
                </> : null
              }
              {
                user ? <>
                  {user.role === "Admin" && (<>
                    <li className="nav-item">
                      <Link to="/referal/student" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Refferal Student
                          <span className="right badge badge-danger">New</span>
                        </p>
                      </Link>
                    </li>
                  </>)}
                </> : null
              }
              {/* {
                user ? <>
                  {user.role === "BDM" && (<>
                    <li className="nav-item">
                      <Link to="/referal/user-list" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Refferal Student List
                          <span className="right badge badge-danger">New</span>
                        </p>
                      </Link>
                    </li>
                  </>)}
                </> : null
              } */}
              {
                user && <>
                  {user.role == "bdm" && (<>
                    <li className="nav-item">
                      <Link to="/referal/link" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Refferal Link
                          <span className="right badge badge-danger">New</span>
                        </p>
                      </Link>
                    </li>
                  </>)}
                </> 
              }
              {
                user && <>
                  {user.role == "Admin" && (<>
                    <li className="nav-item">
                      <Link to="/referal/link" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Refferal Link
                          <span className="right badge badge-danger">New</span>
                        </p>
                      </Link>
                    </li>
                  </>)}
                </> 
              }
              {
                user ? <>
                  {user.role === "BDM" && (<>
                    <li className="nav-item">
                      <Link to="/referal/link" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Refferal Link
                          <span className="right badge badge-danger">New</span>
                        </p>
                      </Link>
                    </li>
                  </>)}
                </> : null
              }




              {
                user && user.role == "Admin" && <>
                <li className="nav-item">
                <Link to="/counselling/dashboard" className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    Counselling Dashboard
                    <span className="right badge badge-danger">New</span>
                  </p>
                </Link>
              </li>
                </>
              }
              {/* {
                user && user.role == "Admin" && <>
                <li className="nav-item">
                <Link to="/examinations/dashboard" className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>
                    Examination Manage
                    <span className="right badge badge-danger">New</span>
                  </p>
                </Link>
              </li>
                </>
              } */}










            </ul>
          </nav>

        </div>

      </aside>
    </div>
  )
}

export default Menu