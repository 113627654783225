import React, { useState } from 'react'
import Dashboard from './Dashboard'

const UploadMarks = () => {
    const [formData, setFormData] = useState({
        user_id: "663f4ab45dacb3399e5e5bf3",
        application_no: "110520246381",
        name: "",
        email: "",
        mobile_no: "",
        profile_image: "",
        phy: 90,
        che: 90,
        math: 77,
        gk: 90,
        obtained_marks: 347,
        rank: 2155,
        prog_opted: "engg",
        total_marks: 360,
        phase: 3,
        moe: "HBE",
        loe: "PCM (12th Level)",
        login_enabled: true,
    });

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : files ? files[0] : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form submitted:", formData);
    };
    return (

        <Dashboard>
            <div className="container mt-5">

                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <img style={{height:'100px', width:'100px' , borderRadius:'50%'}} src="https://ucarecdn.com/29419ad5-07c9-4a1d-8749-f71e476c04c1/-/crop/312x312/0,14/-/preview/" alt="Student Photo" />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Application No</label>
                            <input
                                type="text"
                                name="application_no"
                                value={formData.application_no}
                                onChange={handleChange}
                                className="form-control"
                                disabled
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label className="form-label">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="form-control"
                                disabled
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="form-control"
                                disabled
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label className="form-label">Mobile No</label>
                            <input
                                type="tel"
                                name="mobile_no"
                                value={formData.mobile_no}
                                onChange={handleChange}
                                className="form-control"
                                disabled
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        {[
                            "phy",
                            "che",
                            "math",
                            "gk"
                        ].map((subject) => (
                            <div className="col-md-3" key={subject}>
                                <label className="form-label">{subject.toUpperCase()}</label>
                                <input
                                    type="number"
                                    name={subject}
                                    value={formData[subject]}
                                    onChange={handleChange}
                                    className="form-control"
                                />
                            </div>
                        ))}
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-4">
                            <label className="form-label">Obtained Marks</label>
                            <input
                                type="number"
                                name="obtained_marks"
                                value={formData.obtained_marks}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Total Marks</label>
                            <input
                                type="number"
                                name="total_marks"
                                value={formData.total_marks}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Rank</label>
                            <input
                                type="number"
                                name="rank"
                                value={formData.rank}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Program Opted</label>
                        <input
                            type="text"
                            name="prog_opted"
                            value={formData.prog_opted}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label className="form-label">Mode of Examination</label>
                            <input
                                type="text"
                                name="moe"
                                value={formData.moe}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Level of Education</label>
                            <input
                                type="text"
                                name="loe"
                                value={formData.loe}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="form-check mb-3">
                        <input
                            type="checkbox"
                            name="login_enabled"
                            checked={formData.login_enabled}
                            onChange={handleChange}
                            className="form-check-input"
                        />
                        <label className="form-check-label">Login Enabled</label>
                    </div>

                    <button type="submit" className="btn btn-primary w-100">
                        Submit
                    </button>
                </form>
            </div>
        </Dashboard>

    )
}

export default UploadMarks