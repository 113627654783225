import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../Header'
import Menu from '../Menu'
// import React from 'react'
import { useAppContext } from '../context/appContext'
import axios from 'axios'
import YoutuberRefferal from '../pages/YoutuberRefferal'
import BdmRefferal from '../pages/BdmRefferal'
function Dashboard({children}) {
  const { user } = useAppContext()
  const navigate = useNavigate()
  const [mbaPartial, setMbaPartial] = useState([])
  const [application, setApplication] = useState()
  console.log("application" + application)
  const [partial, setPartial] = React.useState()
  useEffect(() => {
    axios.get('/counselling/dashboard-count-data').then((response) => {
      setApplication(response.data.data)
      console.log("application", response.data)
    })
  }, [])
  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">AICEE - Examinations - 2025</h1>
              </div>{/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">Dashboard v1</li>
                </ol>
              </div>{/* /.col */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        {children}
      </div>
    </div>
  )
}

export default Dashboard


