import React from 'react'
import Dashboard from './Dashboard'

const TotalStudent = () => {
  return (
 
        <Dashboard>
            
        </Dashboard>
    
  )
}

export default TotalStudent